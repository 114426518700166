import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import { Link } from "gatsby";

const chevronLeft = require("../assets/chevron-left.png");
const chevronDown = require("../assets/chevron-down.png");

export default function Dropdown(props: any) {
  const [state, setState] = useState({
    listOpen: false,
    headerTitle: props.title,
  });
  const [pathname, setPathname] = useState("");
  const [hash, setHash] = useState("");

  useEffect(() => {
    const handlePathname = () => {
      setPathname(window.location.pathname);
      setHash(window.location.hash);
    };
    handlePathname();
  });

  function toggleList() {
    setState((prevState) => ({ ...prevState, listOpen: !prevState.listOpen }));
  }
  const isActive = (link: string) => {
    let linkHash = link.split("#");

    if (linkHash.length < 2) {
      return pathname === link;
    } else {
      let urlHash = hash.split("#")[1];
      return urlHash === linkHash[1];
    }
  };

  return (
    <div className={styles.root}>
      <div className="dd-header">
        <div className={styles.title_mob} onClick={() => toggleList()}>
          <div style={{ display: "inline-block" }} className={props.active ? styles.active : ""}>
            {props.mainLink ? (
              <Link
                to={props.mainLink}
                className={styles.title}
                style={{ color: props.active ? "black" : "white", textDecoration: "none" }}
              >
                {props.title}
              </Link>
            ) : (
              props.title
            )}
          </div>
          {state.listOpen ? <img src={chevronDown} alt="" /> : <img src={chevronLeft} alt="" />}
        </div>
      </div>
      <div className={""}>
        {state.listOpen && (
          <div style={{ marginTop: 10 }}>
            {props.list.map((item: any, index: number) => (
              <Link
                to={item.link}
                style={{ color: isActive(item.link) ? "black" : "white" }}
                className={[styles.li_mob, isActive(item.link) ? styles.active : ""].join(" ")}
                key={index}
              >
                {item.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
