import { graphql, StaticQuery } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/layout/layout";

const query = graphql`
  query SiteTitleQuery {
    pageLables_header: sanityPage(pageName: { eq: "Header" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }

    pageLables_footer: sanityPage(pageName: { eq: "Footer" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    headerLinks: sanityHeaderLinks(name: { eq: "Main" }) {
      links {
        keyName
        redirectTo
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
  }
`;

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(false);
  function handleShowNav() {
    setShowNav(true);
  }
  function handleHideNav() {
    setShowNav(false);
  }

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          );
        }

        return (
          <Layout
            {...props}
            showNav={showNav}
            siteTitle={data.site.title}
            labels={[...data.pageLables_header.labels, ...data.pageLables_footer.labels]}
            links={data.headerLinks.links}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
          />
        );
      }}
    />
  );
}

export default LayoutContainer;
