import React from 'react';
import * as styles from './footer2.module.css';
import companyDetails from '../../companyDetails';
const logo = require('../../../../static/assets/images/tab_header_icon_black_117x117.png');
const phone = require('../../../../static/assets/newDesignImages/phone-2@1x.png');
const mail = require('../../../../static/assets/newDesignImages/path-38-2@1x.png');
const mailTwo = require('../../../../static/assets/newDesignImages/path-39-2@1x.png');
const facebook = require('../../../../static/assets/newDesignImages/path-34-2@1x.png');
const linkedIn = require('../../../../static/assets/newDesignImages/path-35-2@1x.png');
const linkedInTwo = require('../../../../static/assets/newDesignImages/path-36-2@1x.png');
const address = require('../../../../static/assets/newDesignImages/path-37-1@1x.png');
import SvgIcons from '../CompanyPage/SvgIcons';
const footerLinks = [
  { text: 'הצהרת נגישות', url: '/Accessibility' },
  { text: 'הלוואות לחברות', url: '' },
  { text: 'הלוואות למשרדים', url: '' },
  { text: 'הלוואות הקמה', url: '' },
  { text: 'הלוואות גישור', url: '' },
  { text: 'מימון הון חזור', url: '' },
  { text: 'מימון מלאי', url: '' },
  { text: 'מימון ציוד', url: '' },
  { text: 'מימון ייבוא', url: '' },
  { text: 'מימון לעסק', url: '' },
];
type FooterProps = {
  labels: Array<Object>;
  links: Array<{ keyName: string; redirectTo: string }>;
};
const Footer = ({ labels, links }: FooterProps) => {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerGridContainer}>
        <div className={styles.footerGridItem}>
          <div className={styles.footerHeading}>צרו קשר</div>
          <div>
            <a className={styles.linksWithText} href={`tel:${companyDetails.phone}`}>
              <h3>{companyDetails.phone}</h3>
              {SvgIcons.phone}
            </a>
            <a className={styles.linksWithText} href={`mailto:${companyDetails.mail}`} target='_blank'>
              <h3>{companyDetails.mail}</h3>
              {SvgIcons.mail}
            </a>
            <div className={styles.links}>
              <a
                className={styles.svgLinks}
                target='_blank'
                rel='noopener noreferrer'
                href={`https://api.whatsapp.com/send/?phone=${companyDetails.whatsappNumber}`}
              >
                {SvgIcons.whatsapp}
              </a>
              <a className={styles.svgLinks} target='_blank' rel='noopener noreferrer' href={companyDetails.facebook}>
                {SvgIcons.facebook}
              </a>
              <a className={styles.svgLinks} target='_blank' rel='noopener noreferrer' href={companyDetails.linkedIn}>
                {SvgIcons.linkedin}
              </a>
            </div>
            <a
              className={styles.linksWithText}
              target='_blank'
              rel='noopener noreferrer'
              href={companyDetails.navigationLink}
            >
              <h3>{companyDetails.address}</h3>
              {SvgIcons.location}
            </a>
            <div className={styles.locationImg}>
              <iframe src={companyDetails.mapIframeLink} width='100%' loading='lazy'></iframe>
            </div>
          </div>
          <div className={styles.complianceText}>
            כל פנייה בנושא תלונת ציבור ניתן להפנות לממונה על הטיפול בפניות הציבור של החברה (להלן: "הממונה"). ניתן לפנות
            אל הממונה בערוצי התקשורת המפורטים לעיל. אזרח ותיק רשאי להגיש תלונת ציבור בעל פה באמצעות פניה למשרדי החברה.
          </div>
        </div>
        <div className={styles.footerGridItem}>
          <div className={styles.footerLogo}>
            <img className={styles.footerLogoImg} src={logo} />
          </div>
          <div className={styles.address}>
            {companyDetails.companyBusinessName} הוא סימן מסחרי של חברת
            <br />
            {companyDetails.companyNameAtTheRegistrar} ח.פ. {companyDetails.companyID}. החברה
            <br />
            היא חברת אשראי פרטית בעלת רישיון מתן
            <br />
            אשראי מורחב מספר 59128 על פי חוק הפיקוח
            <br />
            על שירותים פיננסים מוסדרים ואינה בנק.
            <br />
            אי-עמידה בתנאי ותשלום הלוואה עלול לגרום
            <br />
            להליך הוצאה לפועל וחיוב בריבית פיגורים.
          </div>
        </div>
      </div>
      <div>
        <ul className={styles.footerLinks}>
          {footerLinks.map((item) => (
            <li>
              <a {...(item.url ? { href: item.url, target: '_blank' } : {})}>{item.text}</a>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};
export default Footer;
