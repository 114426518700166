import React from 'react';
import companyDetails from '../companyDetails';
import SvgIcons from '../layout/CompanyPage/SvgIcons';

import styles from './whatsAppChat.module.css';

const WhatsAppChat = () => {
  return (
    <>
      <a
        className={styles.root}
        target='_blank'
        rel='noopener noreferrer'
        href={`https://api.whatsapp.com/send/?phone=${companyDetails.whatsappNumber}`}
      >
        {SvgIcons.whatsappGreen}
      </a>
    </>
  );
};

export default WhatsAppChat;
