import * as firebase from "firebase/app";
import "firebase/auth";

let config;
if ("prod" == process.env.GATSBY_FIREBASE_AUTH_ENV) {
  config = {
    apiKey: "AIzaSyA4Kis3jiA0CH6FTBq93yfO-jLLptCHwMs",
    authDomain: "ftpf-prod.firebaseapp.com",
    databaseURL: "https://ftpf-prod.firebaseio.com",
    projectId: "ftpf-prod",
    storageBucket: "ftpf-prod.appspot.com",
    messagingSenderId: "65387401346",
    appId: "1:65387401346:web:9bdc31d6630222293b649b",
    measurementId: "G-B32NK8NE21",
  };
} else {
  config = {
    apiKey: "AIzaSyDGWOPrDYLCbEoUsyLMpX91T7d8Yo89lko",
    authDomain: "ftpf-98d1d.firebaseapp.com",
    databaseURL: "https://ftpf-98d1d.firebaseio.com",
    projectId: "ftpf-98d1d",
    storageBucket: "ftpf-98d1d.appspot.com",
    messagingSenderId: "354475924390",
    appId: "1:354475924390:web:e9aa9c8a8caa93af314d67",
    measurementId: "G-RZD10YR8XJ",
  };
}

if (typeof window !== "undefined") {
  firebase.initializeApp(config);
}

export const signIn = async (email: string, password: string) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

export const isLoggedIn = () => {
  return !!firebase.auth().currentUser;
};

export const onAuthStateChange = (callback: (user: any) => void) => {
  return firebase.auth().onAuthStateChanged(callback);
};

export const getCurrentUser = () => {
  return firebase.auth().currentUser;
};

export const logout = async () => {
  return firebase.auth().signOut();
};

export const register = async (email: string, password: string) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};
