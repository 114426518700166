import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import styles from './menus.module.css';

type IMenuProps = {
  title: string;
  menuItems: any[];
};

export const Menus = (props: IMenuProps) => {
  return (
    <div className={styles.dropdown}>
      <button className={styles.dropbtn}>{props.title}</button>
      <div className={styles.dropdownContent}>
        {props.menuItems.map((item) => (
          <Link to={item.link}>{item.title}</Link>
        ))}
      </div>
    </div>
  );
};

export default Menus;
