export default {
  phone: '03-7408040',
  // spell-checker:disable-next
  address: 'דרך מנחם בגין 23, תל אביב, מגדל לוינשטיין (קומה 12), 6618356',
  navigationLink: 'https://ul.waze.com/ul?preview_venue_id=22806849.227937414.244667&navigate=yes',
  linkedIn: 'https://www.linkedin.com/company/64960612',
  facebook: 'https://www.facebook.com/habankaimfinance',
  mail: 'info@UltraFinance.co.il',
  whatsappNumber: '97237408040',
  mapIframeLink:
    // 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381.2415882630157!2d34.784098415224896!3d32.062713881193595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4b70932837e1%3A0x951ae782c177b1b9!2sKhoma%20u-Migdal%20St%2016%2C%20Tel%20Aviv-Yafo!5e0!3m2!1sen!2sil!4v1642605118328!5m2!1sen!2sil'
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381.19931281509!2d34.7799216!3d32.0638575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4b7b7f293a69%3A0x1be3e572b6083859!2sLevinstein%20Tower%2C%20Tel%20Aviv-Jaffa!5e0!3m2!1sen!2sil!4v1707055494214!5m2!1sen!2sil',

  companyBusinessName: 'אולטרה פיננסים',
  companyNameAtTheRegistrar: 'אולטרה פיננסים בע"מ',
  companyID: '515953990',
};
