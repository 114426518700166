import React from 'react';
export default {
  chat: (
    <svg width='40px' height='40px' viewBox='0 0 24 24'>
      <g>
        <path fill='none' d='M0 0h24v24H0z' />
        <path d='M7.291 20.824L2 22l1.176-5.291A9.956 9.956 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.956 9.956 0 0 1-4.709-1.176zm.29-2.113l.653.35A7.955 7.955 0 0 0 12 20a8 8 0 1 0-8-8c0 1.334.325 2.618.94 3.766l.349.653-.655 2.947 2.947-.655z' />
      </g>
    </svg>
  ),
  users: (
    <svg width='40px' height='40px' viewBox='0 0 24 24' fill='none'>
      <path
        d='M10 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM4 8a6 6 0 1 1 12 0A6 6 0 0 1 4 8zm12.828-4.243a1 1 0 0 1 1.415 0 6 6 0 0 1 0 8.486 1 1 0 1 1-1.415-1.415 4 4 0 0 0 0-5.656 1 1 0 0 1 0-1.415zm.702 13a1 1 0 0 1 1.212-.727c1.328.332 2.169 1.18 2.652 2.148.468.935.606 1.98.606 2.822a1 1 0 1 1-2 0c0-.657-.112-1.363-.394-1.928-.267-.533-.677-.934-1.349-1.102a1 1 0 0 1-.727-1.212zM6.5 18C5.24 18 4 19.213 4 21a1 1 0 1 1-2 0c0-2.632 1.893-5 4.5-5h7c2.607 0 4.5 2.368 4.5 5a1 1 0 1 1-2 0c0-1.787-1.24-3-2.5-3h-7z'
        fill='#0D0D0D'
      />
    </svg>
  ),
  shield: (
    <svg width='40px' height='40px' viewBox='0 0 24 24'>
      <path d='M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z' />
    </svg>
  ),
  briefcase: (
    <svg
      width='40px'
      height='40px'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    >
      <rect x='2' y='7' width='20' height='14' rx='2' ry='2' />
      <path d='M16 21V5a2 2 0 00-2-2h-4a2 2 0 00-2 2v16' />
    </svg>
  ),
  percent: (
    <svg
      width='40px'
      height='40px'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    >
      {' '}
      <line x1='19' y1='5' x2='5' y2='19' />
      <circle cx='6.5' cy='6.5' r='2.5' />
      <circle cx='17.5' cy='17.5' r='2.5' />
    </svg>
  ),
  range: (
    <svg width='40px' height='40px' viewBox='2 10 12 12'>
      <path d='M3.75,17 C4.12969577,17 4.44349096,17.2821539 4.49315338,17.6482294 L4.5,17.75 L4.5,19.25 C4.5,19.6642136 4.16421356,20 3.75,20 C3.37030423,20 3.05650904,19.7178461 3.00684662,19.3517706 L3,19.25 L3,17.75 C3,17.3357864 3.33578644,17 3.75,17 Z M11.75,11 C12.1296958,11 12.443491,11.2821539 12.4931534,11.6482294 L12.5,11.75 L12.5,19.25 C12.5,19.6642136 12.1642136,20 11.75,20 C11.3703042,20 11.056509,19.7178461 11.0068466,19.3517706 L11,19.25 L11,11.75 C11,11.3357864 11.3357864,11 11.75,11 Z M7.75,14 C8.12969577,14 8.44349096,14.2821539 8.49315338,14.6482294 L8.5,14.75 L8.5,19.2487201 C8.5,19.6629337 8.16421356,19.9987201 7.75,19.9987201 C7.37030423,19.9987201 7.05650904,19.7165662 7.00684662,19.3504907 L7,19.2487201 L7,14.75 C7,14.3357864 7.33578644,14 7.75,14 Z'></path>
    </svg>
  ),
  database: (
    <svg x='0px' y='0px' viewBox='0 0 512 512' width='40px' height='40px'>
      <path
        d='M256,0C136.384,0,42.667,42.176,42.667,96v320c0,53.824,93.717,96,213.333,96s213.333-42.176,213.333-96V96
    C469.333,42.176,375.616,0,256,0z M448,416c0,35.307-78.848,74.667-192,74.667S64,451.307,64,416v-64.384
    c34.197,32.043,106.347,53.717,192,53.717s157.803-21.675,192-53.717V416z M448,309.333C448,344.64,369.152,384,256,384
    S64,344.64,64,309.333v-64.384c34.197,32.043,106.347,53.717,192,53.717s157.803-21.675,192-53.717V309.333z M448,202.667
    c0,35.307-78.848,74.667-192,74.667s-192-39.36-192-74.667v-64.384C98.197,170.325,170.347,192,256,192s157.803-21.675,192-53.717
    V202.667z M256,170.667c-113.152,0-192-39.36-192-74.667c0-35.307,78.848-74.667,192-74.667S448,60.693,448,96
    C448,131.307,369.152,170.667,256,170.667z'
      />
    </svg>
  ),
  phone: (
    <svg width='40px' height='40px' viewBox='0 0 28 28'>
      <path d='M7.92072596,2.64472005 L9.58060919,2.14438844 C11.1435675,1.67327249 12.8134781,2.43464008 13.4828053,3.92352899 L14.5160823,6.22200834 C15.0865915,7.491081 14.7859439,8.98254111 13.7683291,9.93148073 L11.9633958,11.6146057 C11.9410906,11.6354021 11.9227774,11.6601187 11.9093651,11.6875342 C11.720585,12.0734103 12.0066606,13.1043886 12.9061418,14.6623357 C13.9199541,16.4183102 14.7035571,17.1131712 15.0654726,17.005305 L17.4348517,16.2809111 C18.765101,15.8742119 20.2066891,16.3591908 21.0206203,17.4872349 L22.4880851,19.5210248 C23.440761,20.8413581 23.2694403,22.6628821 22.0872853,23.782427 L20.8252038,24.9776653 C19.9337363,25.8219337 18.6854328,26.1763171 17.4833291,25.9264007 C13.966189,25.1951903 10.8150019,22.3628582 8.00336279,17.4929565 C5.18895293,12.6182556 4.31270988,8.46966127 5.44310245,5.05625686 C5.82703045,3.89692852 6.75144427,2.9971717 7.92072596,2.64472005 Z M8.35362603,4.08089467 C7.65205693,4.29236569 7.09740832,4.83222008 6.86705161,5.52781682 C5.89305385,8.46896164 6.6820141,12.2043134 9.30240089,16.7429565 C11.9202871,21.2772684 14.7578382,23.8276999 17.7886493,24.4578029 C18.5099109,24.6077526 19.2588899,24.3951235 19.7937719,23.888561 L21.0558584,22.6933179 C21.6924034,22.0904861 21.784653,21.1096654 21.2716737,20.3987168 L19.8042088,18.3649269 C19.3659382,17.7575185 18.5896985,17.496376 17.8734103,17.7153679 L15.4990066,18.4412788 C14.1848357,18.833027 12.9496858,17.7377562 11.6071037,15.4123357 C10.4705242,13.4437223 10.075962,12.0217729 10.5619671,11.0283539 C10.6558865,10.8363778 10.7841758,10.6632305 10.9404443,10.5175321 L12.7453325,8.83444937 C13.2932789,8.32348189 13.455166,7.52038798 13.1479688,6.83704116 L12.1146918,4.53856182 C11.7542848,3.7368524 10.8551022,3.32688524 10.0135093,3.58056306 L8.35362603,4.08089467 Z'></path>
    </svg>
  ),
  mail: (
    <svg x='0px' y='0px' viewBox='0 0 474 474' width='40px' height='40px'>
      <path
        d='M437.5,59.3h-401C16.4,59.3,0,75.7,0,95.8v282.4c0,20.1,16.4,36.5,36.5,36.5h401c20.1,0,36.5-16.4,36.5-36.5V95.8
   C474,75.7,457.6,59.3,437.5,59.3z M432.2,86.3L239.5,251.1L46.8,86.3H432.2z M447,378.2c0,5.2-4.3,9.5-9.5,9.5h-401
   c-5.2,0-9.5-4.3-9.5-9.5V104.9l203.7,174.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.3,0.2,0.5,0.4,0.8,0.5
   c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.2,0.8,0.4,1.2,0.6c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.6,0.3,1,0.4c0.1,0,0.3,0.1,0.4,0.1
   c0.3,0.1,0.6,0.2,0.9,0.2c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.7,0.1,1,0.2c0.1,0,0.2,0,0.3,0c0.4,0,0.9,0.1,1.3,0.1l0,0l0,0
   c0.4,0,0.9,0,1.3-0.1c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.1,1-0.2c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.6-0.2,0.9-0.2c0.1,0,0.3-0.1,0.4-0.1
   c0.3-0.1,0.6-0.2,1-0.4c0.1,0,0.2-0.1,0.3-0.1c0.4-0.2,0.8-0.4,1.2-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.3,0.8-0.5
   c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.3L447,109.2V378.2z'
      />
    </svg>
  ),
  location: (
    <svg x='0px' y='0px' viewBox='0 0 368.666 368.666' width='40px' height='40px'>
      <path
        d='M184.333,0C102.01,0,35.036,66.974,35.036,149.297c0,33.969,11.132,65.96,32.193,92.515
  c27.27,34.383,106.572,116.021,109.934,119.479l7.169,7.375l7.17-7.374c3.364-3.46,82.69-85.116,109.964-119.51
  c21.042-26.534,32.164-58.514,32.164-92.485C333.63,66.974,266.656,0,184.333,0z M285.795,229.355
  c-21.956,27.687-80.92,89.278-101.462,110.581c-20.54-21.302-79.483-82.875-101.434-110.552
  c-18.228-22.984-27.863-50.677-27.863-80.087C55.036,78.002,113.038,20,184.333,20c71.294,0,129.297,58.002,129.296,129.297
  C313.629,178.709,304.004,206.393,285.795,229.355z'
      />
      <path
        d='M184.333,59.265c-48.73,0-88.374,39.644-88.374,88.374c0,48.73,39.645,88.374,88.374,88.374s88.374-39.645,88.374-88.374
  S233.063,59.265,184.333,59.265z M184.333,216.013c-37.702,0-68.374-30.673-68.374-68.374c0-37.702,30.673-68.374,68.374-68.374
  s68.373,30.673,68.374,68.374C252.707,185.341,222.035,216.013,184.333,216.013z'
      />
    </svg>
  ),
  linkedin: (
    <svg x='0px' y='0px' viewBox='0 0 310 310' width='40px' height='40px'>
      <path
        id='XMLID_802_'
        d='M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
		C77.16,101.969,74.922,99.73,72.16,99.73z'
      />
      <path
        id='XMLID_803_'
        d='M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
		c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z'
      />
      <path
        id='XMLID_804_'
        d='M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
		c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
		c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
		C310,145.43,300.549,94.761,230.454,94.761z'
      />
    </svg>
  ),
  facebook: (
    <svg width='40px' height='40px' viewBox='0 0 24 24'>
      <path d='M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z' />
    </svg>
  ),
  files: (
    <svg
      width='60px'
      height='60px'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    >
      <path d='M14.5 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7.5L14.5 2z' />
      <polyline points='14 2 14 8 20 8' />
      <line x1='16' y1='13' x2='8' y2='13' />
      <line x1='16' y1='17' x2='8' y2='17' />
      <line x1='10' y1='9' x2='8' y2='9' />
    </svg>
  ),
  coffee: (
    <svg x='0px' y='0px' viewBox='0 0 296.001 296.001' width='60px' height='60px'>
      <path
        d='M290.664,148.038c-5.78-10.667-15.653-18.438-27.799-21.882c-4.126-1.17-8.375-1.764-12.629-1.764
		c-7.386,0-14.474,1.792-20.789,4.997c0.021-3.326,0.004-6.442-0.013-9.328c-0.01-1.766-0.02-3.454-0.02-5.061c0-4.418-3.582-8-8-8
		H8.042c-4.418,0-8,3.582-8,8c0,1.606-0.01,3.295-0.02,5.061c-0.179,31.228-0.512,89.354,50.826,150.104
		c1.521,1.798,3.756,2.836,6.11,2.836h115.539c2.355,0,4.59-1.038,6.11-2.836c4.799-5.678,9.145-11.334,13.083-16.946
		c10.572-1.611,85.224-14.84,101.98-68.771C297.602,171.8,296.533,158.869,290.664,148.038z M60.71,257.001
		c-43.783-53.478-44.84-103.064-44.703-134H213.45c0.137,30.935-0.92,80.522-44.703,134H60.71z M278.393,179.699
		c-10.523,33.866-52.082,48.877-74.857,54.675c17.735-31.75,23.395-61.319,25.152-84.879c5.563-5.662,13.268-9.103,21.548-9.103
		c2.778,0,5.559,0.39,8.264,1.156c7.962,2.258,14.389,7.27,18.097,14.112C280.449,162.77,281.07,171.082,278.393,179.699z'
      />
      <path
        d='M72.729,82.001c0,4.418,3.582,8,8,8s8-3.582,8-8c0-2.462,0.414-3.341,1.238-5.094c1.166-2.477,2.762-5.868,2.762-11.907
		c0-6.039-1.596-9.431-2.762-11.907c-0.824-1.753-1.238-2.632-1.238-5.094c0-2.461,0.414-3.341,1.238-5.093
		c1.166-2.476,2.762-5.867,2.762-11.905c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,2.461-0.414,3.341-1.238,5.093
		c-1.166,2.476-2.762,5.867-2.762,11.905c0,6.038,1.596,9.43,2.762,11.906c0.824,1.753,1.238,2.632,1.238,5.095
		c0,2.462-0.414,3.342-1.238,5.095C74.324,72.571,72.729,75.963,72.729,82.001z'
      />
      <path
        d='M104.729,82.001c0,4.418,3.582,8,8,8s8-3.582,8-8c0-2.462,0.414-3.341,1.238-5.094c1.166-2.477,2.762-5.868,2.762-11.907
		c0-6.039-1.596-9.431-2.762-11.907c-0.824-1.753-1.238-2.632-1.238-5.094c0-2.461,0.414-3.341,1.238-5.093
		c1.166-2.476,2.762-5.867,2.762-11.905c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,2.461-0.414,3.341-1.238,5.093
		c-1.166,2.476-2.762,5.867-2.762,11.905c0,6.038,1.596,9.43,2.762,11.906c0.824,1.753,1.238,2.632,1.238,5.095
		c0,2.462-0.414,3.342-1.238,5.095C106.324,72.571,104.729,75.963,104.729,82.001z'
      />
      <path
        d='M136.729,82.001c0,4.418,3.582,8,8,8s8-3.582,8-8c0-2.462,0.414-3.341,1.238-5.094c1.166-2.477,2.762-5.868,2.762-11.907
		c0-6.039-1.596-9.431-2.762-11.907c-0.824-1.753-1.238-2.632-1.238-5.094c0-2.461,0.414-3.341,1.238-5.093
		c1.166-2.476,2.762-5.867,2.762-11.905c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,2.461-0.414,3.341-1.238,5.093
		c-1.166,2.476-2.762,5.867-2.762,11.905c0,6.038,1.596,9.43,2.762,11.906c0.824,1.753,1.238,2.632,1.238,5.095
		c0,2.462-0.414,3.342-1.238,5.095C138.324,72.571,136.729,75.963,136.729,82.001z'
      />
    </svg>
  ),
  pencil: (
    <svg width='60px' height='60px' viewBox='0 0 15 15'>
      <path d='M11.8536 1.14645C11.6583 0.951184 11.3417 0.951184 11.1465 1.14645L3.71455 8.57836C3.62459 8.66832 3.55263 8.77461 3.50251 8.89155L2.04044 12.303C1.9599 12.491 2.00189 12.709 2.14646 12.8536C2.29103 12.9981 2.50905 13.0401 2.69697 12.9596L6.10847 11.4975C6.2254 11.4474 6.3317 11.3754 6.42166 11.2855L13.8536 3.85355C14.0488 3.65829 14.0488 3.34171 13.8536 3.14645L11.8536 1.14645ZM4.42166 9.28547L11.5 2.20711L12.7929 3.5L5.71455 10.5784L4.21924 11.2192L3.78081 10.7808L4.42166 9.28547Z' />
    </svg>
  ),
  shekel: (
    <svg width='60px' height='60px' viewBox='-32 0 512 512'>
      <path d='M248 168v168c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V168c0-75.11-60.89-136-136-136H24C10.75 32 0 42.74 0 56v408c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V112h112c30.93 0 56 25.07 56 56zM432 32h-48c-8.84 0-16 7.16-16 16v296c0 30.93-25.07 56-56 56H200V176c0-8.84-7.16-16-16-16h-48c-8.84 0-16 7.16-16 16v280c0 13.25 10.75 24 24 24h168c75.11 0 136-60.89 136-136V48c0-8.84-7.16-16-16-16z' />
    </svg>
  ),
  tick: (
    <svg width='40px' height='40px' viewBox='0 0 16 16'>
      <rect width='16' height='16' id='icon-bound' fill='none' />
      <path d='M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z' />
    </svg>
  ),
  whatsapp: (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 300 300'
      //style='enable-background:new 0 0 300 300;'
      xmlSpace='preserve'
    >
      <image
        //style='overflow:visible;'
        width='300'
        height='300'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAEsCAYAAAB5fY51AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJ
bWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdp
bj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6
eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LmIw
ZjhiZTkwLCAyMDIxLzEyLzE1LTIxOjI1OjE1ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9
Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2Ny
aXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEu
MC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpz
dFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1w
OkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgMjMuMiAoV2luZG93cykiIHhtcE1NOkluc3Rh
bmNlSUQ9InhtcC5paWQ6RTg4QjA5NjM0RUU0MTFFRDk0ODNBN0FGMDhCNzAwNDIiIHhtcE1NOkRv
Y3VtZW50SUQ9InhtcC5kaWQ6RTg4QjA5NjQ0RUU0MTFFRDk0ODNBN0FGMDhCNzAwNDIiPiA8eG1w
TU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFODhCMDk2MTRFRTQxMUVE
OTQ4M0E3QUYwOEI3MDA0MiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFODhCMDk2MjRFRTQx
MUVEOTQ4M0E3QUYwOEI3MDA0MiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6
eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pu81i6UAAELZSURBVHja7J0HQFTX0sf/VKWJICgg
YKGKgiBq7GJNrIkpaoox5aW8l8QU04stvdcvvZhuYoxGE3uNiQqKDUGxoQjSBERQQEC+M2eviTGK
3LuF3b3z85tvNY+ye+65/ztzzpwZh/r6ejAMw9gCjjwEDMOwYDEMw7BgMQzDgsUwDMOCxTAMw4LF
MIzOcOYhYM7DQViAsBDl1f+cVx9hLRXzVF5pDnkp3+surNl5P69a2Cnl7+XCaoUdF1ahvJKVCisS
ln/O6xHllfNumL8nJ+dh6fZBFS4sVliYYh2FdRDWVpirlbzP08JyhR0SduAcSxO2XxE/hgWLsSOC
FWEi66JYzAU8IVuDPLcMYbsUS1Nej/AlZ8FibAMPYb2E9RbWQ1hPJZzTExRGbhaWImyTsI3CTvLU
YMFimh5aM0oSNkRYP2HdwOuS50Nh41ZhfwpbKWwt/l5TY1iwGHNeLyWku0LYcEWkmvOwqKJKEa9l
wpYqYSTfBCxYjAm9KBKoMcprAA+JyUNIEq5Fyit7XyxYjEpaCLtS2DWKJ+XGQ2IRKoUtFzZP2C/C
TvCQsGAxF6aZ4kXdIGwEh3pWETouEfad4n1V85CwYDFAV2H/UYTKl4fDKilRhOszYdt5OFiw9Iav
IlC3CEvk4bApUoXNVgSshIeDBcueIXG6X9h42H7ipt6hEHGusLcUEWNYsOwCJ2FjhT0gbAAPh13y
u7C3YVior+PhYMGyRWin7zZh98FwTo+xfw4Ke1fY5+AdRhYsG4HWpx4UNkURLUZ/kFi9I+xN8DoX
C5YVC9VDikfFQsWcFa53FeEq5uFgwbIGWp0jVF48HMwFKFeE6w0WLhaspsJTCf0eYaFiVAjXa4pw
VfBwsGBZAiorfYuw54QF8nAwGsgT9jQM+VxneDhYsMxFkvJ0TOChYEzANmU5YS0PReO9BebSRMKQ
Y7OGxYoxIQnKnPpNWBQPB3tYxkLZ6I8LewKcmc6YF8qcf0nYi+CD1ixYGhgk7AN+8jEWZq+w/wpb
zUPBIWFjoHZWXwpbxWLFNAG0/LBSmYP+PBzsYTXEzTAsqrfioWCsAMrZmqqIF8OC9RdthH0MwyFl
hrE2Fgq7U1gBh4TMtTA0ImCxYqwVmpsZylxlwdIplJ1Op+qpppEf3xOMleOrzNXPoeOTFXoNCbsL
mwNDi3aGsTUOCJsobAt7WHYu0DBU/PyDxYqxYcKUOfyAMqfZw7JDvGE4u3UVz3fGjlgg7FZhx1mw
7IdYGHrNRfD8ZuyQfTD0sEzjkND2oVh/I4sVY8dEKHP8ehYsG/Yehc2CoR2TB89pxs6hOf6tMuft
dl3LXkNCau3+ueJdMYze+AGGda1KFizrJwCGUjA9ed4yOiZF2JXC8jkktF6o7XsyixXDyHuA7oV4
FizrZAgMuSmhPFcZRkL3wnrl3mDBsiKuhqFqoyfPUYb5B57KvXE1C5Z1QN2VfwRXBGWYi9FMuUdu
Y8FqWh4W9qkwJ56TDNMgTsq98jALVtPwgrBXobOzVAxjBA7KPfOCzX4AG01reNXWnxQM08RQZd2p
7GGZn+dZrBjGaKgf4ossWOZlhrAnea4xjEmgFnYzWbDMA7X2ns5zjGFMyjTFbAJbWcN6VNjLPLcY
xmxQs+CXWLCMh5pKvs/ziWHMzv9gaB7MgqUR6hbyMzjPimEsQR0MGfELWbDUcxkM7brdeR4xjMU4
JWwwDAenWbAaSbiwDeBW3QzTFBQJ6yNsv7W9MWvcJSSRWsJixTB8D1q7YLnCUHwvnOcMwzR5lLNQ
uSdZsC4C7Qb25rnCMFZBL1jZrqE1Cda9wm7nOcIwVgWVpLnPWt6MtSy6DxC2wtrcT4ZhJKeFDRe2
jgULCBGWCl5kZxhrhnYOuwvL1nNISB7VAhYrhrF6/JV7tUkr+zo38SC8IqwbzwUrjgVOn0ZpaSmK
CouQk3MEuTk5OJqbh4L8fJw4XoaKygqcrj6NyqpKVFVVo0Z8PX3PuZ47/c3F2RkuLi5wdXVFczc3
uLu7w7VZM7Tw8oK/vz/aBLRB27Zt0TY4GIGBgfDx9YW3tzccHLg+oxWRAEMtuil6DAnHwJDCwDPS
ygTqwIEDyMzcg4P7DyDnSA6OCDt27Biqq6tQW1OD2tpa1NTVok681tXVSUU6c+YMGjuXHB0d4eDo
APrj7OwkzAXOQsxI1JyFeQkRCwoKQpAQsJB2oQgPD0dUVJT8N9Pk0EWmfoeL9CRYQcJ2CmvF179p
OXXqFDL37MGutF1IT9+FvZmZKD5WjKpK4TEJgaqqrhbCVHeBmaNMXXNNTOFZkUfWvHlzg7k1R3BI
CKKjoxHTuTNi4+IQFhbGF7BpKIah32GOHgSLDjKvFJbE171pOHr0KLZs3oxtqVuFSKWjsLAQFeXl
OHnyJGqEB2UBPdKMG4WTHh4ylGzXvj3iusahe48e6N69hwgxeZPZgqwVNhSGA9N2LVhULGwmX2/L
knMkG3+s/wPJmzaJcC8Tx0tKcbysTIaANovwwjzcPeDT0ht+rVujW2IikgYl4bJeveDkxAU+LAAV
1Jxlz4KVKGwTmn6xXxeQx7Rm9Wr8vnYd0tPSUHTsmFxAt9HGIw2Gj/SnWbNmaOXvh+DgYPTq3UuI
1yB0iY3liWA+yLuibPgt9ihYrsoH4xlkZvbs2YPlS5dJb+rw4cMoLCiQi+J6wtPTE4FBQejcubMU
rqTBg+R/Y0xOGgz5WRZx1S0pWDNhQ7WjbZENf27A0sWLkbplC47k5OCU8LAYICAgEO07dsDQoUMw
bPhwmTrBmJRZsFC/BUsJFu0opAhz4WtretatXYvFv/2GbVtScehwtvCm6nhQLgDldXXo2BGDBg/G
FSNH8C6j6aCdmp7CttuDYLkoYhXP19W0rF29Gr8tWoTUbdtw5NBhuQhtb+tT5oB2GsMjwkWoOBij
x4xGWDhXMzIB2xXRqrF1wXpK2HN8PU3HFhHyzZ/3MzZu2IAj2dksUhpp5uqK6JhOGDpsGK6+5hq0
CQjgQTEOWvJ51pYFq4OwDGHN+VoaD2WgL/h5PtasWoW9e/dKoWKxMvoWgLu7G+IT4nHlVVdh9Nix
creR0USVsBhhWbYqWPOFXcXX0TgqKipE6Pcrfpk/H1u3bpVHY6zvtne46CErmmKOypnAM/XWuVtJ
79/PrxX6DxyIaydch549L+OJpw06ID3OFgWL6ucs4+tnHJs2bsR3336HDX+sR1nZiSb1qM4eRKb3
cFag6BAzHZ1xa+YGNy/xd+GdyLOBzs7nfP0ZVFeflgejq6qqcIqO/VRWobLylMysPyN/Hv5x0Llp
Pqf8VIiMjsSokaOlcLVu04YnoXpGCFtqS4JFPjWdFYzka6eN0pIS/PDDD1i4YAH27d2HejooU285
YTo7L+SZPlcX+LXyQ2BQIPxbt0YrH1/x6o+WPj5o4e0NTw9PpQpDc/lKWeZOJFj4W7BImMhOn66R
h6grhWCVnyhD2YkT8rMWFhbhmLD8wnyZN3ZCiLPhI9f/4/1YRrYgBNcVSUlJuHHyTejXvz9PSHXs
gyHfstpWBOtxYS/yddNGSkoKvvpitsxSr6ajM2a+WR3+ViopDLT9H9qunbBQtG/XXlZMaC2EisrA
tGrVCt4tW5p8naesrEwIVymOHStCYZGw/HyZ9EqVIrLFK5msDIGzw2FmAXOQw4H27TvguvETcM21
16KVH5/VV8ETwl6yBcGiSgyZwjitWCWVIlT6RXhU337zLfZkZJg9NDobgjmKu7Ndxw7o2LEjIqOi
EBERgdD2QrBCQmRdqqaCPLKC/AIhWtlSvHaLMTl48CAO7NuPoqJCg6ooXph5xscgjrSbOHL0aNx4
8yTEx3N2TiOpEBYtLNfaBesjYXfy9VJHbm4uZs+ejblz5uBkxUnzCZUiUg7ix3eMCBfiFI74hAR0
iolBeFiYVW/t02YDCde+vXuxc8cOpKWlYX/mXhQXF0vJMt+YGcYrtmtX/OfOOzBi5EguLNg4PoeJ
G8uYWrAiYEhj4MPNKtiamoqPPvgQq1etMpvHINeBxCuFdjExnZDYvYdBqDpFy7UoW+RQ1iEpWptF
CJ22cwd2795t2EE1m9Y7yLB48q23YsLECTY7bhaEYviuwtKtVbDmCJvA16mRV7OuDiuWLcdHH34g
C+iZJQR0MIRN0dFR6CoEqm/fPrIMS0BgoF2N5Y7tO7B5UzI2bdiInbt2mq0qBflVjk5OmHD99bjt
9tvRvkN7nsgN8ysM1YWtTrCo3nMquORxo6BKn/MX/IyP3v0Aefl5ZhEqKkUc26ULevfpg4GDktCj
Z0+7H9eigkKsW7cO69auQWrqVvHvArM4XJRXljRkMO6bMkVWP2UapK+wDdYmWKSko/jaXBp6+n/7
3bf49OOPcLLspIlDQINQdekSg/4DBspDvrQ+pbsHwsmTWCVC7OXCg922datsmmHqhwKFiLGxsXjs
ySdk0UDmoqyCoTqp1QhWP2Hr+bpcmgLxxP/is8/w9eyvDN1lTPz8j4iMwMCkJHnMRI9CdT7V1dVY
tmQpfl20EDu2bUdxSYnJRYvSPx5/4kkMu3w4T/CLkwQTNGI1lWBRjfYhfE0aJl885d9/7z388N33
MrvbVE98umnatGmDPn374Nrx49HzMj5Wcj7l5eX49ZeF+OmnecjI2IWa06YrKiAX4/388PDjj2Pc
1eN4B/HCrDaFRphCsMgX3sjXo2Hyjubhnbffwk8/zpX/NoVY0Y3h1rw5YrvGYfyECbhy3Dge6Etw
5MgR+cBYvmyZzOkyFRSGt2zpg0cef1RWfuCa8hekj7FaYQrBov5ko/laNOxZvf7qq7LSgim9KurT
N3LUSNx6++0yXYFpPBv+/BNffP45NienyMPlpsDJ0QmeXp54/KkncfXVV8vjScw/MHrH0FjBokUS
2o9nH/gi0FETEqt5c+eZrK46HTbuGheH2+68A0OGDuVB1sipk6fw1Zdf4ud585AlvC1TPEykp+Xt
jWmzZuKKESPkIXDmL2iAu8CQq9kkgvWFsFv4OlwYOh/3xuuvyxCkVjYjNf6G8PPzw4hRo/C/e++R
SYyM8VD7s48//ABbU7fKahKmwLeVL2Y99xyGDRsuPC0OD8/hK2GTm0KwKPPwEAzdcJh/Pb1P4oP3
38cnH32i1K8yTqyo8kFo+1DcceddmHjD9TzAJqakpARvvfEmFi/6FcfLjpskZG8bEoznn38evfr0
4TWtv6HuOu2F5WkKu2fMmKH1Fz8mbBCP/78hgfr222/x7ltvm6TYHj2hExO7Y9YLz8tyvozpoTrv
1JyChIUW48sryo3+mSfKy5GdlYXwyAgEBgby7qEynYVVCltjSQ+LvKpsYVzd7PwgXYzniqXL8dCD
94vwwvhyQFQgL0ncSNNmTJfhIGN+Vq5YgXfffgd7du/+q6SN5rvT0Ul4WL0wfdYsWQ2DkRQIC4WG
XoZaPayJwm7mcf83dCZw6gMP4MSJE0b/LDpcO37iRLzw0otSuBjL0DEsDDGdO+PggYMoLCw0arOE
HmBHc4/KCqsJ3RLgxteRoNJTe2Eo8mkRwfpQWAiP+3mPjfwCPDDlXhzOPmz0z6I0hf/cdRfuf/AB
HtgmICAgAN26d5OFA6n0jzGeFolWekY6PNzc5QF03jk0DDEM5WdU4ajhF9G2ZB8e739CmdSvv/oa
MtJ3G70ZSOsdU4RQ3XHnHTywTUiHDh0x87nnMGToEFnEzxho9eqbb76VnbmNDTPthD6KlphdsG7j
sf4ntBU+/+efhc0zblJTMmhQEB6Y+hAmXs87gdYAPTyenj4dl48YIevVG+NlHTtWjC+/+AK70nby
wBpQXdxPrWDRFZvE43yeu5+ejtdeedWo2uskVoEiDLn33ntl/XDGeqBzmlSRYdjw4UaGc2dkwcHZ
X3wuPXJGaomq5gBqBYvKx/BW1TlilZ+Xj1dffEnmXRkTCbby88N/7r4L4znHympF64mnnpR1xYwS
LTFJli9Zjq++/IpDQzHtobIklVrBYu/qHOgM2pzvvkdqaqpRP6eFVwtMumUybp48mQfViqEqrU9P
m4aePXvKIzga9Qqna2rwy8/zsXHDBh5U4CZzCZYvuEDfP7wraoTw4YfvG3UGjZ7W1LDznnvu4UG1
AUJCQuQ5wfCICM2JoDRfsrIO4pvZs3FSeOY6Z5SiLSYXrKvBx3D+Ijf3MN5/7yPU1Wp36x0cHDH6
yrF4cOpUHlAbIjw8HDOfnQUvLy9jnnhI2ZKKzz79rEm7eVsBroq2mFywuLmEAlWxXLtmCTZt2KT9
h4inc5++vTHl/vvlsRDGtqCOQzOffVa7lyWMkouX/PYb0nft0vtwNlpbGitYtNCexNPUwJ70DLz/
7tdwcNLmXdEkDwsLwx133SVDDMb2cHFxkaV9/nvvPbIhhVYv6+D+A/jy8y/0PpxJaORmXmO3O8aB
ew1KSktKsHDhLzh2rFBTGgOJFbV5p0X2Pn37mvVALB283rJlC9av+x0HD2Sh7kwN3N09ZFkaX19f
eHi4y0d9x/CO6NW7D2dgq8RdjN8dd96JXTvTsP733zWFdnVn6pCyOQVLFi+WDVp1Ck28q4R9esn7
p5GDzB1xFFavXIkHpkzBqVOV2rwr8eemyZPwyGOPmfV8IPXpe/ftt5GSnCyF668tdAdyq8Ufp7+d
a6pQMHL0KDzy6GNo5deKL7IqJ6kehw5lYdINN8oUFy3Q+Pe4rCe+/PprPZeh+Q2NqFzcmJDQQ9hg
npoQEzIPv/76G05Vaivy5iBGu2f3yzB+wkSzitVvv/6Ke+7+L/5Yv172P6TuPCRY0mrrUFNbI9fh
zhp9zcL5CzB//s+orKzkC63SY27Xrj0eevhhuDpr25Oi65K5JxNzf/xRz0NJGuNpCsGiAky8KizY
vHkzli1ZrDkU9PT0xPU334DIqEizvcf5837GjGnTUZCfp6oW12nxtXO+/RaHTdiYQS9QTtaYsWNx
+YjLNddxLystxdwffpQPD51CGnPJet+NEawRPCWBnJwcLFu6THgr2tpD0UrVhOtvwOAhQ8zm9v+y
YAFefvFFlJaUqs+6FyJ85EgOtqRuFV5YLV9wldAi/NRHH5FnD7VAbd8OZR3EvHnz9TyMl1zEa4xg
XcHTEdgivKs1q1ZrWlgl7youLg5jxoyRC7XmYPmSZXjj9ddQXFwMreUiztSdwZ9//om8/Hy+4BoI
DgnB7XfcLq6xh6bvLy+vwNw5P+LkSd16WZcbK1jRMFQG1DWFBQX4fe064V1pqyBKBeAm3HgDoqOj
zfL+dm7fiU8//gRHc3KNSkKk7/zjj/WygwyjjWuuvQ4J8fGaGk/QPDmSfVB2qdYppDWdjBEsLiAu
2LZ1K35ft06bGIjvGTV6NPr262eWPnUVFeX48ovPsDNtB4xPmK6Xi+6pycmo1O9ailHQZgoVXmzp
3VLT95N3tWDePD0fjB5qjGD10/sEpBv4jz/+xPHj2jqp0ILshOsnygqW5uDH7+dg+cqVqDXVBD9T
Lw/lHsnOZvXRSK/evdBvQH+4urpo8rL27M7E+t/X6/XITj9jBKs/e1dbkbxxo6YET/qeq6+9RtYH
13q6vyH2ZmZi6dKlqDxZCZhwcqdlZGDXrnRWHo3QAvwtt92GNgHaFuBPVZ7CvJ/m6lWw+msVrDAY
eg/qmo1/GrwNLZPHzd0N102YAG9vb7O8t0WLFiE9bRdM0aD1XGpO12BzSoqygM9oISYmBv369dNU
pZS8LJp31G7MVN3CbYhARXtUC5buvatsIVTbd2zXFG6RdzVy1CiEhYebxbuixgg0qam2kqkhcU5O
3oR9e/ex8miEUleo41Hb4GA4aBj/shMnsOzX31CvP8FqUHtYsBogedMm7BbhkXq1EmFBM1eMHzcR
LVq0MMt7W7dmDQ4fOmS2z56bkyvrfTFGeFmdY9C9Rw84asm7E6K1dNlSVOizXpYmwdL1gnudcnC4
rLRMQzjogEFJAxAW2dEs3hWxOTkZJ8rKzLbOQU/21C2bZcIso93LGjVqpGwsApVroHRd9+8/gIz0
XXrcMeynVrCoAmCknidb5t692LdnD7SsDzmIb7nmmvHwbOlllvdWkJ+PAwezUGfGRVm6Yaj0847t
21l5jKB7z56I7xqn6XvpaNXy5SvkWVCdEYmLVCG9mGDF632ipW7eIkKuw6rlitauKBSghpnmOoKz
O2M3jpceN+nO4L8ES1jZ8TJs27pN1ZlE5p80b94cvfv0keV8HDR4WevXrtNrh52uagQrVs+TjHZm
tm/fJhc+1eOA0eNGooV3C7O9v8z9+2Vip4OZx4FusK0iLKZKAox2Bg4ejIjISE2bubkiJN+9e7ce
w8I49rAaCR1NyTqg/niKg6MDvNw9MWjAMLMWwzuSn4fqKvOXgaEn/D4RGicnJ7PqGEGbgADEx3eF
k7Oj6ocMCVXyxk16LPsTr0aw4vQ8wbbv2IH8Am0HgBN79kJwaKhZK4mWFhSgqqYG5k4rJMGqqqqW
61gnNHmbzFlot7Bt27aaQnMSLKpbxh7WhQWLXIPOep5caTt3akqapIyZkUMHwsXZvFUjTwnxsFhC
oYNhPCjjnzHCXUhIQDiFhRrWsfbs2Y2jR4/qLfO9My5Qlv1CgkUlBZrpdWJRB+f9e/fhjMr2XeRR
+fj4okffPtryblSECKdr6sy64P7PJ3w9jubmYu2atdyp2Ah8fHzQuUsXODk5yjLZqq55ba30cnW2
W9hM0aJLCpauvat9+/ahqLBIdbhFO4JdOscgQLj95gwH6Wdb9DlbD1lWOXPPHuRznSyjiI2NRYCG
84X0bKIkXh1WI+3SGMHqoOdJtSttF8rKjqteHKUE0V69e5ulhMz5v6e5q+X72VZXVeFkhe67FBtF
REQEwsMj1HUDhaGzzv69e/UoWO1ZsC7B/gP7hWCVqfdfhYj06dPHIusM3t4tLNxdpR6BQUEICAwA
o52AwEB0COuoKZo/cOAAio8d09uQdWyMYHXU64SitYKc7GzU1NSqCrsoTKPJaK6DzufjH9AGzZo3
t9i4+LdujZGjRprtXKReoFSXMCFYzTR4yFTY79ChQ3pbR+zAgtUABYWFyMvLU7uRIydiRFQkPDTW
8lZLu9B2cLOAYDkoNmjoEAwcNIgVxxTXrn17BAeHqF7npK+ncjM6Cwsv6WHRAoxue6dnH842NABQ
6bO7urigU6cYi73PyKgozY0O1EDeYlx8PCZef73FxNjeCQ4OFqLVTtP3HsrKkrvYOiIE56U2nC9Y
wcJc9DqZjmQfRlVlpepdOPKw6PygpYiKjkIrX1+z/57Q0FDc98D9cneLMV14Hdg2SPX30ZzMPnRY
c8dxG8VF0aSLClY7PU+mw9nZmo5ANHNzQ8ewMIu9T1pLCouIgLOZFt4p/AgObouHHn0ESUlJrDIm
hA5DBwWqFyzy+mnJokxjbwEbJrQhwfLT82TKO3oUVVVVqm/uNm1am63JxMVI6JaAli1bmvRn1jsA
Tg6O8GvlhykPPogRI0eCMT3UbNXVVX1uNonVsWLd7RT6s2BdADrqUnysWHUpFUovaBscIhsPWJJ+
/fujbUiISSs2UAZ2m5AA3PvAFIy7+mpWFjPh28oXfn6t4Khy4Z1y4UpLS/U2XH4NCVZrvU6iiooK
lGs44EtCFRRk+V4dtHgb3Skajs5OJhEt8hT9W/rh7vv/hxtvusms2fp6h5qS+Lf2V18fS5gOc7Fa
NyRYvnqdRJQsqqV+Ni24t27Tpkne84CkgfD39zeJuNDnuOGWSbhu7HhWFLMLVkv4+bfGGQ0ZpKUl
JbpzSDkkvJCHVV6ufv1K/HF2dpGi0RT07dsPMZ27qK4AcCEcHR3Qu09vi4e2esTTyxMtNbR+owfT
iRO6qz7aYEjor9dJRNVFz6jNIhY64ejkCB/fpnFMPT09MXToEJNkoFMGdXb2Ya7IYAFop5BES8sZ
neP6W8NqMCRspddJROtXdSoX3OncIC26+/r4NNn7HjRkCLoldjPay6qtrcPiXxfLukuMeXFzc5Pr
WFpOnZbpr757gyGhbutg0aK7loapzZo1g4fwdJoKCkeHXX4FWgovy9jAcP26dfhj/R/cdMLMUGhH
c0ZLU4py/VV+9WhIsNz0Oolo/apewzPPxdlZilZTMmz4MPQbMAAORh68PlN/Bj98/72sMMqYl2Ya
SwRVVuquxEyDguWq1wlUc7oG9WfUCxalFTS1YFEC6egxo2UCqzHQE5wK9S1cuFCPtZcsCm3WaKG6
Sne13RsULN1uEWldbKbkP2vYWRs8dChGjBotPT5joHBw4c/zsWbNal6ANyNO4kGnpRSRDptRNHj4
2V2/6wravs/RwdEiNbAu+T7Ee7hq3DjEd+tmVF4WeVknysvx/TffyXLRjHmgh5yTo5OGeaq7hF6X
hgTLkaeS7UIVIybecAPc3Yx77pBoJW/ahB9/+EGPbdItAo1xPc7wQFwa94YEyku/E0jb99FCdW1N
jdV8DlqAv/HGG4wuoUw31M9zf8LPP83j0NAM1IgHQW2t+nG1Bm++SSMJnjpnXXRnTXHhmTP1qLEi
wXJ3d8eYcVehS2yc6nZS53Py5El8/dWX2JySoreeeGanrk6bd2XJ0ti2IFjleh0IV1dXTetY5F1V
WdlCaKeYGNw8eZJc2DXWy9qbuReffvSxLB3NmA6toXZzFqx/Ogx6HQg34ZloWQSlnn2VVpgCQDXY
b7x5ktGLtCRa69auxbtvvc3rWSakqroK9Sq7d8uEUw/d7YudakiwdJt849VCW+ssmnjlVnhcgnKz
xo+fgE6dY4wXLWG/LlqId958i5XGFOFgbS0qKGNdw3Xx8vLW23DVNCRYNXqdRNRkgRY01d7ctCB9
3ErL1kZGReK++6co94VxolVZWYUf5vyATz7+hBXHSCqrqlBWpu2ITcuWuhOs2oYES7c+P1U80LID
Q5VKS6y0RhGJ72WX9cK9995nigo0KC0twezPPsec7+aw6hgT45w8qdkr9/bWnWCdbEiwKvU6ibxb
eAt320v1btjpmtPIz8+33lBXfKZrJoxH334DjN41JAoLC/Deu+/gu2+/ZeXRCB20P368VNPhZ68W
uss8alCwqvU6ibxbGgRLtYdVdwaF+QVW+7nopggKCsIjj09Fm6DWRosW3TQFQqDfe/sdfDV7NquP
BmgJoahIQ6lj8Sxt5ae7GpsNLrqX6HUS0XaxlqdXTW0tjubkWPVnI9GKiorG9BnPwBSpdyRahYWF
+L9338O7QrgsTdrONHzy4Uf4Xnh51FzU1qBy3EWFRaqTlR3EpfP11V0V8+Jz/3H+SdlCvQoW3dSt
WwfA1dUFp083fu+Bdnxyco7gpHDzm7Iu1qWg6gB9+yXhwakP4a03XtNUT/xfM6m4GF989iny8/Lw
+FNPavJQ1TL3x7n4+P33UVBUJA+e05gnJnbDdRMnon///jYx144J74pCQqgsZ+Tl6QWfJiwW2UQU
NuRh6a4lx7kEtQ2Em4ZzeKXCxc8+csTqP5+7uwdunHQjxo67ymSHaKnG+MJffsF9/7sHBw8eNOv7
37hhAz754ENkHTokF65pLYjC0xXLV2Dq/Q/gzv/cgd/XrbP663A0N1dTpruhPZjuqpgf45DwIoSE
hqK5m/oahjU1tTiwf79NfEZaq3v8ySdlI1aYqKshdcsmMblLCMbiRb+a5X0fyjqEV158SYhV1gXG
v0bu1K5bswaPTX0Yd995F9b/vt5qw0ESLLXFIukB49emjazjrzNKGhIs3YaERPv27YVgNVftfdTU
1iBjV4bNfE4/Pz+88PLLCAsPM9nPpHy0LOFhzZoxEy8+97xJk2nLjpdh+lPPID09vcFdXHoPRceO
Yc2qVXj80Udx3z33YOPGDVY19nTEKTf3KBxURuSU1BwaEgo3d90VBeaQsCEPq4VXC/WCJZ7wezP3
yJwsWyE8PBwvvfIyggKDTOu/Fx/D9999h1tvnixCteVG/zwa26cefwIpKZsaPb4kXBQqLl+6DE88
8igeefBBpG7ZYiXhYA4OZx8Q/pW6OUa70VFRkfD08OCQ8ByK9SxYlJRHHZXVCg+dJ8zMzJRdeW2p
qkFCt26Y8fyz0uNyMGHTeyqvvH3bdsycPgNPPfGE5rUtGtcZz0yT1U9rNDTGIOHKycnFwkWL8NjD
D+PpJ5/CDvG+mpKsg1koKqR7sPFzjB6gFEKGhrbTtGRh4xQ1JFiH9CxYNDHad2gvD0JrWZvYtWuX
zZVhGThwIJ6ZMV2ubZm2mGW93D2cP28e7r/3Prz/3v+pPhHw8ksvYeGCX4wuC0zCd+jQYfz04494
+KGHpJBmiPDS0pCQ799/QAip+jni7++H4NAQPVYczT73H+enNVBCEe3p67a2e0RUtPS0qsTkUjOt
ampOY+OfG5A0aJBNfV5aG7n8iitk16AXnn1OCq8poRSRPbt3I+dIDn5ftxaXjxiBcePGoeUltuff
fP11zPn+e1RWme7wBdWrz8rKEl5XDjb8+ScGDx6Ma667FuEREZa58w5ny7wxtQ81+vqIyEg9niOs
UTTpooJFfjftz3fUq2DFdekMH+8WKMjLh5o8Gdqm3pKSgqrKSk0eWlPi7OyMMWPHSk/k5Rdf0Hww
t6EbrqKiHKlbUqWHQWtLQ4YOwWjxOwMCAv719R++/z6+mv2lTF0wy11QUyN3dXOFcK3//XcMEsJF
pXgu9F5MCa1z7qPdZPKSVIgWeVWdOneBh4fudgiP4LzDz04zZsw4/4vG6FmwaNs4Ze067D18GA4q
BEs2uRQ32KDBg2RzU1tz3cnTIk+jVatW2LptK6orzXNKizw52tbfsX2HELDNKCoqgneLFvDx9ZUC
8uknn+LLL2bjhAUahpLHdezYMaSnpSE5ORkBbQIQIsIuc5Qhpvkxb+5cJG/YqOn7J996C2I6ddJb
ieRUYV835GERWdAxzi4uiO4UjVXCW6qmwnwqnoTkXVHiYkxMDBXftrnPTv0Vx11zDVzF61uvvYGj
eUfNtiZHaQ+bN2+WqQpLf1sMn5Y+KBde2L79+4RnZdmybFTuZacQ0M8/+xQh7ULRsaPpn9eUR7Y7
Y49q74q+3qdlS8RER8PJyBZuNsi/tOhCI3AQOicyLg7+9MRXuY5FN/fKFSvE0/BWm03wo1LRY6+8
Eh7uHnjz9TdE6LTPsJ9lDuGqhxSn9Ix0Jfp2ANB0mxaHDx1CmZlqm+3Yvh379u7V9ACIj0+45Jqf
nfIvLXJkD+vfJPTogcCgIE2Lo5l7MsWTdLdNd5qh8HD4FZdj2szpiI2PlzJi1gC3/l9/aRIchQdj
jlCe0mQ2b05BqTw/qA56N7379EFzd122DD3UGMFKh86hdZwusbGa1guofdPi336V6yO2Dt0ozz7/
nFwgN9zI9r2l7uXhJT1MU5ORkYG0tDRN3lUzFxck9uwuw3Udkt4YwRKBtn7rYv3lZSXEGxIq1RZZ
E7ZkyVIUWHGNLDXQetxTzzyDGyfdhGau1F7dPvuUkJi0bu0vD4ibGmrikXVAfToDPTAjo6MRGhqq
x36EpEG7GyNYtexlAd0SE9G+QwdN31tcVIS1a9bYTZcZOrJ0/0MP4aFHn0SbgDBx89hnj8J27ULh
49PSpD+TSvBsTk5GZZX6jQTqeTli9CjZa1KHZOC8lIaLCRaxU++CRTk5XeJipYelel1DbmH/aLW1
3rVAXXhu/8+teHraM+jVu5c8ymNPWdf0WcIiIuBp4ppeq1esQka6toPxdNB5YFISXFx0mce944Je
p5ov1hu9LuslywurDi/kusVupCSn2MVa1rlcfsUgTJvxLCbfdqts3GEXoiU+goe7JzqGhWlq9XYx
qOzOipUrUFpaqno/gUJAKkyo03CQ2K5GsHTvYcmwsHsiYoWXpW1RBPj2m69RfMz+zpNTgumjjz8m
q4wmiNBZS3s0ayO+WwKC2rY16c9ct3Itdu7Udtia1ruuvPJqvXpXF9UgRzXqpjcoDOrWvTuaC9dc
7e1Ip+u3bUlFyuZku/OyCNpNu278eMx6dhZuvmUy2rYNtlnREnKLgYMGonXr1ib7mVWVVVgw72cU
FxVrKtbXtm2QfE869a5Uh4S0+LKPJQvoN2AAOsV01upk4ZvZX+Lo0aN2Oz7RnTrh6WnT8Mz0aRg1
apRMCbG17Ic2AQHo0aOHSVMHVq5agW3pWw3t6DWEgyPEWFJJZB1WZyD24iLVjxuS7/VgEBEejj49
e2pqK05u/dbUbVi/fr3d7BhejCHDhuL5l1/CE08/haFDhsLHlzKzrf9mI0GYNHmyXL8yFVTx4psv
vxbeVYmmeeMtPHs6ImVrpYpMyB8X94Y1fJPe6N+/Lzp0aC9GS8MNKL5l7vffY2/mXrsfJw8PD1w1
bpwsv/zY409gyNDBMsyy1pRT6rpDGf1UYsaUqQM/z5unuWS2k7MT+vbrh8jISL16Vw06Sxeq1nAW
Kso9heUK8Gnlh8KCQmzdulXT95eUlMpa8Yndu+tiEZW242M6d8aAgQMREhKM5s3dZPmdsrLjVuM1
kBZQWZlnpk9HmzZtTPZzc3Nz8fysZ1FUqK09QsuWPnhSeKladqftiEcuFhI2JFh08OkuYV7QOSQy
Do6OSNm4EeUVFaq/n86SHTlyBFHR0ejQUT+Ve6g5LX1mKhAYERkhS8iQz0Db/dVVVU0jVMIoHWP0
mLGy0qopa2DRdX75+RewadMmTfX9KaWCvKs77rpLz7dbnrCntHhYRE9hncHIBpalJcexfds2Td9f
WVmF8vITSExMlDeM3qBa+f3690PvPr3RWogElfGhxWgq7EdelyXCH/odlJJx+513YOojD5u8osba
1avxzttvay7p7O/nh0efeFx4pSF6vtWWCpurVbAChY1guTLUimrWvBk2bdggG3iqpx6Hsw7BWwhf
bFycbvNrWnh7o2t8V4waPUp6XVRLvq6uVlY7Jc/LXEIVGBiIAUmGztcjR40yuUBSIcDHHnkE+fn5
mr6f1q769e+Pu/57t95vtY+EpVzsf7xURbCVLFV/07lzZ4y77hq8/8572tZixE3y3ZwfEB0WhiEj
Ruh5UVVu3ffp21carftQqeLUzZuxKz0dRQWFOG6CulT0O9q0CUCnzjEYe+VYIZKjzTLmtAP83jvv
ytJCWtfoAgODcOvtt/FNdgnNcWjEAB8WFsrjaIBKhUy95x7sP3RY0+Q84+CI/j26Y8bzz6GDhZof
2BJU5G7DH3/KDY59+/bieOlxKV6XSgs5K0MUalLITZU2OnfpjKHDhmPw0CGybr05oDmw+LffMPX+
B1CrsQYavbdrx1+H5154Qe+XnzrktDNWsD4WdgffSgZqTtfgp6+/wozX30DdKfUn8OnQsIurK2bN
nI5rr7+eB7QBaKNia2oq0nbuxP59++XO28mTJ6V4UZ9CJ+FBUca9h6enLCNM3Y5aB7SRO5SX9eol
O3lb4gF21+23i1CwQLPXTeWPX3/rTURERen9kn8i7M4Gxb0RP2QxC9bfuLi6IOnyy9Fr/Xr8uW6d
+iKZwhWI6hSNyE6deDAvAS0+k1151VWygiu1eT+SnS1TTKhJBe1CBgQGoF27dvBv3RpuFm4ymiME
9ZUXXtQuVgLK/xo9ZjSL1d9aA2MFi2JKWg114/FU1huCgzH+2muxYd3vqs+JkcIldk9ENDWqYBoN
bfnTTiOZNVBSXIwPP/wQGzds1CxWtMbWvUcPXD/pJr7ABo255Jp5Y05W0pbYah7Pv6FWVXnUt1DD
PG3u5o6u8fFmKcXLWAbq4EzZ7D9+Pwd1Z7TX7qeWYpMm3wwvrxY8qAaNueT2e2OPgv/C4/k3eUeP
YtXyZarPm9CXx8bGIoIX220WqryxauVKvPLiy7IiqOalBRcXXDFiJAbZWKdwM9IojWmsYC3ABcqV
6hFy/2kdZdu2bepDAQcHxMd3tchiMGMesVq3dh1mTpuuLAVoDwV79+0r6+QzhqFVNMZkglUkbC2P
qwi0RTiwOWWzPBun1rtyE+FgQkI3uVjM2J5YUa7YLCFWtOCvdd2K0sBoI+Gmmyfp/bzguaxVNMZk
gkX8yONqONy6Zctm9c9WMVETE7ujY3gYD6KNQTuUKcnJePG5F+ROpZZzgmdxdXHFdRPGY/DgwTyw
fzO30d6pih86T9hpPY8qPVWzDh7UWLXBAQnd4hGs73NiNulZbdqwETOemYasrIM4U69drJydnDBq
7FhMvOEGHti/IU35yRyCReUeFut5ZGl3KDV1K85QOKgyJHBzF+Fg90S9NsS0WbFas3o1Zkx7RohV
llGlcaj2Vpe4OFlOmkpvM3+xGBcpJWOsYBFf63lkDx86ZPCu1K+1yxK8IVaSQ8Q0IgysrcPiX3/D
tGeewSGNx7D+ds0hE1zvvf8+dOnShQfXCE1Re8DqN2HUBqaVHkf24IED8piI6qYC4k9ij+4I5EVW
2xCrujp889VXeO/d93D8eKlxYkVPKxFGTnngASQlcQrDeRQrmmI2wapWFPEBvY0snWGjc211Kjvg
yN1BdzckJCRwOGgDlJeX483XXsecOXNw+nS11syFf1z//953H0aNGcODe2HvSlXxMC09hD7T48ju
27sP27ZqKN4nnrA9L+uFthwOWj2UX/fI1Ifxzddf43S1CcRKXPsJEydg0s03W/yco42gWku0CNYu
YRt0J1j79mH37t2awoNeffuYtG44Y3pocf2O2/6D1StXGZW28NeNRQ0uhl+Ou//3P/j5+/EA/5sN
ipaoQmuRoA+E9dHLyJ46eQo7tu2Qaxtq8fTwQFyXLhwOWikU4v/fe/+H7775BsXFxSZpkkGeVc+e
PTH10UekZ63nQo2X0BDVaBUsSiJ9TZgu3IY9e3Zj166dGiauI7onJpq8BTpjGlK3bMFrr7yKtB07
UVVtmqYYJE5duyZgJhVo7NiBxerCFEJjIrpWwaJkLyq29bQ+BGuPtnCw/gwGDh0iQgJ/k7+n0tJS
/L5uHbZuSUWffn1lZxqmcVSUV+Cjjz7ETz/8KLyqEhEC1pnk55I4xcV1xYuvvCgbs7JYXZSPoTEJ
3Zi6se8Le1SYXddJoXAwbUcaztSpP/vt7dMSCd26mSwcPHz4MDanpMjMa8oHKxUhTHVNDRYtXIh1
a9fizrvv5oPVl2Dp4iX49NNPsVc8hE5pqBjbgFohNi5WiNVLsjMPi1WDzs77Wr/ZGMGi/mFzhN1s
z6ObkZGO9HRaG6QJWK9i/lLuVQ/4G+Fd0eJvRno6kjdtwsaNG8VNlik79lB3mRohVH/NgOpq/DJ/
gTzvdvU118jW615eXnxrnAOlpHz5xWz8sX69TF0wxcL6WWjn6rLevfHcC88jtF07FquGmaNoh8UF
i3hZ2CRYZydyk5C2Ywf2ZWZC/VpsPQYNSlJ9DIMEiXofbvhzg7jJtiD7cLYUKPIGGlr0p154lJH9
yYcfYcWy5bL9+jXXXaf77XQK57+e/SVWr1olm1mcK/SmCgOHDB+G6TNmICAwkOXoUjcF8IoxP8BY
wcqAIVN1tD2OLiWLpqXtQq2GcNDXpxUSu/doVGVR2p0iL2q9ePpnZuxBfn4eKsTvPiVM3XSol52p
09LSZAOHBcLrumrcVbhy3DjdeVx0IuGHOT9g3Zo1KCkp0dzctEHPSojVTZMm4Z77p6BVq1YsR5eG
zg2mN6VgES/aq2Bl7EqXCaNawkHq2kKtpi4GJSlu2LBBClVG+m4cLymWoUr1adMUxCBvgjy1Q1lZ
8sYdPnw4Rl85Fh06dLDbu4HCvDXCk1qw4BfsFJ+96NixS7YH0wo9iKY+PBXXTpggu/UwjcLoPmam
ECxKAKN6zHZX4CdVhGQHDuzT5PgOHDLoXy3paaeRFs03JycjU4SZ1HOv7ESZPGgLM8XVJFxkR0Ro
uWjRIimkgwcPQq8+fewmXMw7moeVK1ZgpQiF9x84gGPFRX+Nqak520X64ccexbBhw2QVDqZRrIYJ
Es5N1V1yur0JVllZGbZtpVIydarSGWhCd+zYEaNHj5bftzV1q/SiSPyoVX2pEI8T4mdfaNG33oyf
p+JkBSoOVEjPbu3qVbIuF3Vd7tu/P7rGdZWt0m3t+tBGxNrVq0X4l4bCwkKUitCPDprXm2kk6dp2
F2H+1EcfRnxCgtmas9ops0xyDUyR2auwRJjdJAPJcrjTZ8hUArU7St0SuiGxZw/szsiQAlEswj3K
/bE2PDw85LGR4LbBiOvaFd3Fe+4SG2u16zFU3mdbaiq2Ci81Y08m8goKcexYodm8qXOhpq033Xwz
Jt92K0JDuRG6SlYJG2ptgpVAURTsZMfwnbfewgf/976mXSUSAhpXk+b5mBlq6Onr6ysbklLSY3RU
JKI7xyAqIhq+fr4Wfz+0I5otHha0y7c3cy8yd2fiSI4Q/+JjsidgrQVE6qxXRcdr7rn3XgwdNhQ+
vr4sP+rpCxOdPzalT0ulDCjdfoI9hIM7tm1HbZ22m+Kk2t09K4DElSwnJ0eGwpSOIc3bF22DghDS
IQSBQW0RHNxWbt9Tfhn979QBxtjfS7t4BfkFyDuaK3//0dw88XoERUWFKDteJrP6KbXDkpzNpRox
ahQm3zJZhoDUzJVRzW8wYbEEU3pYBDXcyzCxEFocWRb36WeQl59v0gRDm0Xcu81cm8HNw11uJHi4
e8Bd/N3byxs+Pr7w9mkhvUoPT0/5ddRzz0EImYuLs/SU6s/Uy+MvtGN36lSlCI/LZdoGFccrKS5B
RUW5/O8nKypkRxoSp3PHneaopZIxz/4e6mhzy+234YoRI+QiO6PNURYWDw1VGSzhYRG0pfaFsDts
eZS3bN6M/IICcaOxWBkUw5CYSna85DjO3x4gz4NEysXZGY7i72c9EXolsTGIT71sjVZTW4Oa0zWq
Qm1LiJX8HfWGnoFjxo7BdRMmoFtiovxcjGa+MqVYmUOwiJnCrhfmaYsjTE/4tJ27DAu5fMLiwup1
/mNUeFFkVTb6ic6KFVWFHX/DRPQfMIDrlxkP7TI9Y+ofag7ByhX2PAwJpTYHlRyh3SjaGnewNsVy
OPvi8Jd21Js1GUIH0a4Qqw5hHTFmzFgMHT4MnTp14kExDc8rWmD1gkW8Kew2GNa0bApKZygqLLSq
A6xn34uzixOiIqOlJxDavh22b9+OVctXyOx4E69F2r1IEcHBwRh6+XB5CqBbt25w4rwqU7FP0QCT
Y64rRAe3psCQm2UzUOLhju07cLqmqfvFOtBBNTicOQPvli0RERWFTtHRcqeKjta079hBng3Mz8vD
wKQkrFy2QlYhqKwy7KSxeDUsVO3atUP/gQMwdOgwdOueyPXWTc+DUNlcotHX0MyTe76wq2xllJcs
XoxnZ85CYUFB00R7yg0V2LoNIqMiEdU5RiZ0thciFRoSctFjILm5uUjZlIw1a1bLWlmUBmAQLiVu
1LtQ0eiK/4sW4V6/fv2EWA0UXmo8H6sxDwuEjTPbtTSzYNFJW0pzaG4LI/3EY4/jl/nzzXZg9kJP
+7OS0l6EeDExndEpJgYxnaKFJ9URoR3aq/qZRUVFcg1u08aNwjbhwP79f/8OnXldZx8AlG5Bu329
+vSWzWzjbPAYkg1B+y4xwrJsVbAI2imYZe0jXVpagptuuAmZu/eYzytRRIr+P532DwsPR3R0lBSp
yKho6VX5m6CcMqUfUPLnlpTNSE1Nxa5du3C8tMTwm+vtc5n+/DXHiIgIxMV3lYe9qeorV2K1CNOE
PWvW62wBwaJElhQYEsislkW/LMSsmTNQWlJqlqc9jTKtR9HBaCqhSy3LKUSJEoLl6Wm+DJDs7Gwp
XlRxc0/Gbul1USY/zHhI2NJCRVOY9CokNATRQvi7JiTI9b7Y2FiZ4MpYhO3CegqrMev1tlCoEK+I
ltVm4T0w5X4sX7IEp01SkfJs4kE9WgcEyDWoTpFR6BJHIhWD8MgIi5/0p+RNOpNHB7J37NiB/fv2
yfQNyuZ3UOJSWxAwg/iL8XWoR/PmzRAcEirLEpM4dVGsoTpkjFmoUcRqu9mvvwXXNmYqLqPVQaVJ
rr9ugjxsq/mmFY94MZhya7xt27Zo1649YjrHoLPwpKI6RUvPyprIysoSApYpvS5qEpubmyPrSpWU
FAtx++dRmCZd/6L18noH6UG5ujYTYtQKQcFBYozDxdhGiTA6XHip0Vzxs2mhJZ/pFpkOFpyMVCt4
i7BYaxvtn+bOxQuznpMVP7UIlpu7B0JC2oqQpB26do1Dp04kVJ3R2kaypemw9oEDB5B18CAOZR0S
f98vF/CLjxWjpLQU5efV77rgnFFXlPXioq94ps2aNUdLHx9ZQcJHvAYGBYlQOlyWdgkPD5cVJRir
gEoed4PGtl3WLFhED2EbhVnVNs2U//4PK1asQE1tbaNuKrox6WZqFxoic6IoHInp3FmGI+52sFVe
V1eL3JxcHDmSIz2v/Lx8FBbk45gQMDq6dFKpN19ZVYWqykrZaux0ddU/61KduwZ+zhSjOujOLs5w
cXGV+U/Nhbm7k3nItTwSJxL6Nq1bIyAoUHqrVN6FwzzrnCowdIBPsdQvtHRq72bFfZxpLSOel5uL
Hdu2XVSszoZGJOwBAQHiBgpCGO1Ade0qF3ajRThibzg5Oct1IbJzoVIwVG6ZmmZQkm2Z+Ht5eQVO
VZ7CqQohYiRep6tRJwSMwsqz4+bg6CDX7FycXeDi6goPD3cpTh6eXvBu4SU3I3xFSNdKeFMtvL25
jItthYIplvyFTXEWgc4YDRKWZA0jvnrtWhQfL7vw4IibjA7BUjhCqQcJ3RJEyNcV7XS6RU7eIxmV
XmF0z+/KvWxRmkKwyI2kXoa0o9CkK6VVIqRZvmSZeK38x01JVTeDRChCoV7X+K7ynBn9N4ZhJMXC
blTuZbsXLCJH2K3CfkETFnHZsX27zFGiNk1+/v4iBApFbFwc4uMTkNg9UWZJMwzzDyjWv025hy1O
Ux5PXyTsXRgOSTcJBw8eREBAG5l2QDWQLrvsMtlNhtdQGOai/J+whU31yx2a+IxZMxjqPXfjecAw
Vg8t4/SCmSox2IJgEdQzifKz/Hk+MIzVUgRDWtLhpnwTjlYwENnCroOFEs8YhlENHb0Z39RiZS2C
RawT9jDPC4axSqYKW2sNb8TByuokfQbDDgTDMNbB58Jut5Y3Y22C5ap4W714njBMk5MsbACsaLnG
wQorUdLiO+0chvN8YZgmg8rV0jnBImt6U45WOFA0QCOsbaAYRkdY7T3oaKUDRuo+RtgpnjsMY1FO
Kffefmt8c45WPHAUP1MH6TqeQwxjEeqUey7ZWt+go5UPIB0BuI/nEcNYhPvQhMdu7EGwiA+EPcFz
iWHMyhPKvWbVONrIYL4EC9WMZhgdMl25x6weRxsa1Fmwgf6GDGNj2NR95WCDHYFfFPY4zzOGMUnk
YlPLLQ422sL8dWEP8XxjGM28AcMZQZvC0UYHmwb6ZZ5zDKOJl21RrGzZwzrLI8rgO/AcZJhLQjf7
Y8JetdUPYOuCRfxH2Iewsl6HDGNlUFLo3cI+teUPYQ+CRVwj7DsYqj0wDPNPqNrCDcLm2foHsRfB
IoYKmy/Mk+cnw/xFhbBxwlbaw4exJ8Ei4mHoxhPM85RhZCsuOsi83V4+kKOdXSC6MFQoP4XnKqNz
6B7oaU9iZY+CReQLSxI2l+cso1PmKvdAnr19MEc7vWDUe36CsGdh2MplGD1Qr8z5Cco9YHfY2xrW
haD6PrSV687zmbFjqPAepfh8b88fUg+CRcQJ+0lYBM9rxg6h6qCU2rPT3j+oo04uKF1IWoBcwHOb
sTNoTvfQg1jpSbCI48KuhuHQNHeZZmyd08pcvlqZ27pALyHh+XQXNkdYGM97xgY5IGyisC16++CO
Or3gdKEThM3muc/YGF8K66ZHsdKzh3Uu42GoZe3L9wJjxZQI+6+wH/U8CCxYBgJhqPgwloeCsUJ+
FXYn7DARlENCbdBEuFLYLcqTjGGsxauiOTmGxYoF62LrA9HCvgZnyDNNC83BTsqcZDgkvCRDhL0v
LJKHgrEg+2BYq1rFQ8EelhpownQVNhOct8WYH5pj1G4rjsWKPSxjiRL2lrAreCgYM7BU2IPC9vBQ
sIdlCjKFjYChquk2Hg7GRNBculyZWyxWLFhmCRMpS/528K4No508ZQ7RXFrOw8EhoSWg2vGPKq48
15FnGgPVV6elhZeVvzMsWBanFQxNKe8V5sXDwVyAcmHvwdBt+RgPBwuWtQgXnZ6/j4WLOUeo3lWE
qpiHgwXLWoXrQUW4WvBw6Fao3hH2JgsVC5atQGJ1uyJcHXg4dEGW4lF9JuwEDwcLli3iJOwqYfcL
68/DYZf8AcNiOlX/rOPhYMGyFxKVcPFaYc14OGyaahj6BFDYl8rDwYJlz/gJu1nYHTActmZsB0rw
/ETYV+AdPxYsvY2/sD6KeFEvOW8eEqukTNgPMFRQ+BNcyYMFi4GbsFHCboDhqEZzHpImpUrYEhj6
/FEBvUoeEhYs5sLQDiMt1FOvuWGKmDHmh0RphbB5MCyg804fCxajEg8YqkRcqbz685CYlCIYqiX8
orye5CFhwWJMAx1WT1RCR/K8qDmsMw+LKmqFpQhbqYR6tMN3hoeFBYsxP3QEaKCwwcL6wdC6jAXs
3wJFZVwoV2q1sHUwZKIzLFiMFYSPvYX1UrwvsjY6G4MCxYMi26QYV0ZgwWJshGBhsYp1USwGtp+4
SombGcJ2KZamvB7hS86CxdgXFDaGKyIWdo51FBYkzMVK3meNsKPCDiq2H4Y27WnK32v5UrJgMfqG
FvYDFM+MGsz6K/+mVx8YkltbwlC0sKUibmcLGNK/Hc77eTTBjit/r1BE6Ljyd3qlpMxSGHbr8pVX
qsiZo/ybF8QZFiyGYWzzacowDMOCxTAMw4LFMAwLFsMwDAsWwzAMCxbDMHri/wUYANYIgcJKiWJK
AAAAAElFTkSuQmCC'
        transform='matrix(1 0 0 1 0.3299 0.1371)'
      ></image>
    </svg>
  ),
  whatsappGreen: (
    <svg xmlns='http://www.w3.org/2000/svg' width='360' height='362' viewBox='0 0 360 362' fill='none'>
      <circle cx='170' cy='170' r='165' fill='white' />
      <circle cx='60' cy='300' r='30' fill='white' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M307.546 52.5655C273.709 18.685 228.706 0.0171895 180.756 0C81.951 0 1.53846 80.404 1.50408 179.235C1.48689 210.829 9.74646 241.667 25.4319 268.844L0 361.736L95.0236 336.811C121.203 351.096 150.683 358.616 180.679 358.625H180.756C279.544 358.625 359.966 278.212 360 179.381C360.017 131.483 341.392 86.4547 307.546 52.5741V52.5655ZM180.756 328.354H180.696C153.966 328.346 127.744 321.16 104.865 307.589L99.4242 304.358L43.034 319.149L58.0834 264.168L54.5423 258.53C39.6304 234.809 31.749 207.391 31.7662 179.244C31.8006 97.1036 98.6334 30.2707 180.817 30.2707C220.61 30.2879 258.015 45.8015 286.145 73.9665C314.276 102.123 329.755 139.562 329.738 179.364C329.703 261.513 262.871 328.346 180.756 328.346V328.354ZM262.475 216.777C257.997 214.534 235.978 203.704 231.869 202.209C227.761 200.713 224.779 199.966 221.796 204.452C218.814 208.939 210.228 219.029 207.615 222.011C205.002 225.002 202.389 225.372 197.911 223.128C193.434 220.885 179.003 216.158 161.891 200.902C148.578 189.024 139.587 174.362 136.975 169.875C134.362 165.389 136.7 162.965 138.934 160.739C140.945 158.728 143.412 155.505 145.655 152.892C147.899 150.279 148.638 148.406 150.133 145.423C151.629 142.432 150.881 139.82 149.764 137.576C148.646 135.333 139.691 113.287 135.952 104.323C132.316 95.5909 128.621 96.777 125.879 96.6309C123.266 96.5019 120.284 96.4762 117.293 96.4762C114.302 96.4762 109.454 97.5935 105.346 102.08C101.238 106.566 89.6691 117.404 89.6691 139.441C89.6691 161.478 105.716 182.785 107.959 185.776C110.202 188.767 139.544 234.001 184.469 253.408C195.153 258.023 203.498 260.782 210.004 262.845C220.731 266.257 230.494 265.776 238.212 264.624C246.816 263.335 264.71 253.786 268.44 243.326C272.17 232.866 272.17 223.893 271.053 222.028C269.936 220.163 266.945 219.037 262.467 216.794L262.475 216.777Z'
        fill='#25D366'
      />
    </svg>
  ),
  whatsappBlack: (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='203.000000pt'
      height='203.000000pt'
      viewBox='0 0 203.000000 203.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g transform='translate(0.000000,203.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'>
        <path
          d='M863 1896 c-284 -54 -516 -227 -643 -481 -74 -148 -93 -229 -93 -405
-1 -176 18 -255 93 -405 114 -228 326 -400 575 -467 116 -31 337 -31 450 0
319 88 559 328 647 647 31 113 31 334 0 450 -47 173 -158 349 -293 463 -89 75
-243 154 -354 183 -102 27 -283 34 -382 15z m250 -411 c226 -48 377 -236 377
-470 0 -144 -44 -252 -142 -347 -66 -65 -130 -101 -221 -124 -81 -21 -200 -14
-285 17 l-63 23 -124 -31 c-69 -17 -125 -28 -125 -25 0 3 14 57 32 120 28 102
30 118 18 146 -73 175 -60 347 36 491 48 73 103 120 189 161 107 51 197 63
308 39z'
        />
        <path
          d='M869 1387 c-136 -57 -221 -162 -250 -311 -15 -71 -2 -158 36 -240
l27 -59 -20 -69 c-11 -37 -17 -68 -13 -68 3 0 35 7 71 17 l65 16 70 -28 c158
-63 306 -37 431 77 79 73 117 155 122 268 9 175 -72 312 -228 387 -46 23 -72
27 -155 30 -87 3 -107 1 -156 -20z m8 -155 c13 -8 53 -96 53 -116 0 -8 -9 -25
-21 -40 l-21 -27 28 -41 c30 -43 68 -75 123 -103 l35 -18 28 32 c15 17 34 31
42 31 26 0 116 -51 116 -65 0 -31 -21 -66 -52 -86 -104 -65 -328 66 -422 248
-46 91 21 229 91 185z'
        />
      </g>
    </svg>
  ),
  facebookBlack: (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='203.000000pt'
      height='203.000000pt'
      viewBox='0 0 203.000000 203.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g transform='translate(0.000000,203.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'>
        <path
          d='M885 1883 c-223 -35 -437 -164 -575 -346 -54 -72 -114 -197 -142
-292 -19 -67 -23 -104 -23 -230 0 -127 3 -162 23 -230 72 -249 232 -443 457
-555 337 -167 731 -107 1003 154 119 114 214 285 249 450 22 106 20 272 -6
381 -41 169 -146 347 -267 451 -179 153 -334 214 -559 219 -71 2 -143 1 -160
-2z m383 -415 l-3 -93 -70 -3 c-38 -2 -75 -8 -82 -14 -8 -7 -13 -35 -13 -79
l0 -69 86 0 86 0 -5 -42 c-3 -24 -9 -67 -12 -95 l-7 -53 -74 0 -74 0 -2 -272
-3 -273 -112 -3 -113 -3 0 276 0 275 -55 0 -55 0 0 95 0 95 55 0 55 0 0 76 c0
98 15 153 53 199 49 60 93 75 230 75 l118 0 -3 -92z'
        />
      </g>
    </svg>
  ),
  linkedinBlack: (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='203.000000pt'
      height='203.000000pt'
      viewBox='0 0 203.000000 203.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g transform='translate(0.000000,203.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'>
        <path
          d='M845 1876 c-172 -40 -316 -117 -438 -235 -115 -111 -191 -241 -244
-416 -26 -85 -26 -335 0 -420 51 -170 116 -284 226 -398 176 -183 407 -275
665 -265 105 4 135 10 231 42 290 98 489 309 582 621 26 85 26 335 0 420 -54
179 -130 308 -249 421 -100 95 -201 155 -333 200 -102 34 -120 37 -245 40 -90
3 -155 -1 -195 -10z m-219 -282 c54 -26 80 -79 76 -158 -3 -40 -66 -101 -115
-110 -51 -9 -120 21 -147 66 -73 120 58 263 186 202z m816 -379 c51 -18 101
-62 128 -115 32 -63 40 -146 40 -417 l0 -263 -125 0 -125 0 0 245 c0 260 -4
290 -48 329 -50 44 -151 25 -196 -37 -20 -27 -21 -44 -24 -283 l-3 -254 -125
0 -124 0 0 395 0 395 110 0 c61 0 113 1 118 3 4 1 8 -21 10 -48 l3 -50 47 45
c26 25 63 51 82 57 53 17 180 15 232 -2z m-752 -401 l0 -394 -125 0 -125 0 0
395 0 394 118 3 c64 2 120 2 125 0 4 -2 7 -181 7 -398z'
        />
      </g>
    </svg>
  ),
};
