import React from 'react';
import Header from './header/header2';
import Footer from './footer/footer2';

// import '../styles/layout.css'
import styles from './layout.module.css';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import WhatsAppChat from '../whatsAppChat/whatsAppChat';

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle, labels, links }) => (
  <>
    <Helmet>
      {/* <script src={withPrefix('/scripts/trengo.js')}></script> */}
      <script src={withPrefix('/scripts/accessibility.js')}></script>

      <meta property='og:title' content='Ultra Finance LTD' key='title' />
    </Helmet>
    <Header
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      labelArr={labels}
      links={links}
      showNav={showNav}
    />
    <div style={{ overflow: 'hidden', minWidth: '390px' }} className={styles.content}>
      {children}
    </div>
    <Footer links={links} labels={labels} />
    <WhatsAppChat />
  </>
);

export default Layout;
