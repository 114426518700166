import React, { useState, useEffect } from 'react';
import styles from './header2.module.css';
import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';
import Dropdown from './Dropdown';
import DropdownMobile from './Dropdown/mobile';
import LoginButton from './LoginButton';
import Axios from 'axios';
import { Button, Form, Modal } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';
import clientConfig from '../../../../client-config';
import Menus from './Menus';

const logo = require('../../../../static/assets/images/tab_header_icon_black_117x117.png');
const menu = require('./assets/menu.png');
type HeaderProps = {
  siteTitle: String;
  labelArr: Array<Object>;
  links: Array<{ keyName: string; redirectTo: string }>;
  onHideNav: () => void;
  onShowNav: () => void;
  showNav: Boolean;
};

const EN_WEBSITE_LINK = 'https://www.ultrafinanceisrael.com/';

const Header: React.FC<HeaderProps> = ({ siteTitle, labelArr, links, onHideNav, onShowNav, showNav }: HeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [pathname, setPathname] = useState('');
  useEffect(() => {
    const handlePathname = () => {
      setPathname(window.location.pathname);
      console.log('header');
    };
    handlePathname();
  });
  const companyHeaderDropdown = [
    { title: t(labelArr, 'dropdown_company_about_button', 'heb'), link: '/CompanyPage/#about' },
    { title: t(labelArr, 'dropdown_company_team_button', 'heb'), link: '/CompanyPage/#team' },
    {
      title: t(labelArr, 'dropdown_company_compliance_button', 'heb'),
      link: '/CompanyPage/#comp',
    },
    {
      title: t(labelArr, 'dropdown_company_partners_button', 'heb'),
      link: '/CompanyPage/#partners',
    },
  ];
  const financeHeaderDropdown = [
    {
      title: t(labelArr, 'dropdown_Finance_process_button', 'heb'),
      link: '/FinanceProcess/#financeProcess',
    },
    {
      title: t(labelArr, 'dropdown_Habankaim_adv_button', 'heb'),
      link: '/FinanceProcess/#habankaimAdv',
    },
    { title: t(labelArr, 'dropdown_shotterm_button', 'heb'), link: '/short_term_info' },
    { title: t(labelArr, 'dropdown_longterm_button', 'heb'), link: '/long_term_info' },
    { title: t(labelArr, 'dropdown_discounting_button', 'heb'), link: '/discounting_info' },
    { title: t(labelArr, 'dropdown_spcecial_finance_button', 'heb'), link: '/' },
  ];
  return (
    <>
      <header className={styles.headerGridContainer}>
        <div className={styles.headerGridItem}>
          <div className={styles.headerEnWebsiteLinkDiv}>
            <Link to={EN_WEBSITE_LINK} className={styles.headerEnWebsiteLink}>
              EN
            </Link>
          </div>
        </div>
        <nav className={styles.headerGridItem}>
          <ul className={styles.headerMenuList}>
            <li className={styles.headerMenuListItem}>
              <Link to='/LoginPage'>
                <div className={styles.homePageLoginBtn}>התחברות</div>
              </Link>
            </li>
            <li className={styles.headerMenuListItem}>
              <Link to='/get_in_touch'>צרו קשר</Link>
            </li>
            <li className={styles.headerMenuListItem}>
              <Link to='/blog'>בלוג ותוכן</Link>
            </li>
            <li className={styles.headerMenuListItem}>
              <Menus title={'החברה'} menuItems={companyHeaderDropdown} />
            </li>
            <li className={styles.headerMenuListItem}>
              <Menus title={'מימון עסקים'} menuItems={financeHeaderDropdown} />
            </li>
          </ul>
        </nav>
        <div className={styles.headerGridItem}>
          <Link to='/'>
            <img src={logo} />
          </Link>
        </div>
      </header>
      <div className={styles.root_mobile}>
        <div className={styles.headerEnWebsiteLinkDiv}>
          <Link to={EN_WEBSITE_LINK} className={styles.headerEnWebsiteLink}>
            EN
          </Link>
        </div>
        {/* sme_finance_header_button */}
        <div className={styles.logo_mobile}>
          {!menuOpen && (
            <Link to='/'>
              <img src={logo} alt='' />
            </Link>
          )}
        </div>
        <div
          className={styles.menu}
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <img src={menu} alt='' />
        </div>
        {menuOpen && (
          <div className={styles.side_bar}>
            <div className={''}>
              <DropdownMobile
                title={t(labelArr, 'sme_finance_header_button_txt', 'heb')}
                active={pathname === '/SmeFinance'}
                mainLink='/SmeFinance'
                list={financeHeaderDropdown}
              />
            </div>
            <div className={''}>
              <DropdownMobile
                title={t(labelArr, 'the_company_header_button_txt', 'heb')}
                active={pathname === '/CompanyPage'}
                mainLink='/CompanyPage'
                list={companyHeaderDropdown}
              />
            </div>
            <div className={styles.link}>
              <Link to='/blog' className={[styles.link, pathname === '/blog' ? styles.button_active : ''].join(' ')}>
                {t(labelArr, 'Blog_and_content_header_button_txt', 'heb')}
              </Link>
            </div>
            <div className={styles.link}>
              <Link
                to='/get_in_touch'
                className={[styles.link, pathname === '/get_in_touch' ? styles.button_active : ''].join(' ')}
              >
                {t(labelArr, 'get_in_touch_header_button_txt', 'heb')}
              </Link>
            </div>
            <div className={styles.link}>
              <LoginButton labels={labelArr} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
