import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../../redux/store";
import styles from "../header.module.css";
import * as firebase from "../../../../lib/firebase";
import * as SystemActions from "../../../../redux/store/system/actions";
import { SystemActionTypes } from "../../../../redux/store/system/types";
import { getLocaleWiseLabel as t } from "../../../../lib/localeUtils";

export default function LoginButton(props: any) {
  const [pathname, setPathname] = useState("");
  useEffect(() => {
    const handlePathname = () => {
      setPathname(window.location.pathname);
    };
    handlePathname();
  });
  const { loggedIn, userName, currentPage } = useSelector((state: RootState) => {
    try {
      return {
        loggedIn: state.system.loggedIn,
        userName: state.system.userName,
        currentPage: state.system.currentPage,
        idNumber: state.system.idNumber,
        companyName: state.system.companyName,
        uid: state.system.uid,
        businessId: state.system.businessId,
      };
    } catch (error) {
      return { loggedIn: false, userName: "", currentPage: "" };
    }
  });
  const dispatch = useDispatch<Dispatch<SystemActionTypes>>();
  const onLogout = () => {
    try {
      firebase.logout();
      localStorage.clear();
      let systemData = {
        loggedIn: false,
        userName: "",
        currentPage: currentPage,
        uid: "",
        idNumber: 0,
        companyName: "",
        businessId: 0,
      };
      dispatch(SystemActions.updateSession(systemData));
      dispatch({ type: "UPDATE_SESSION", payload: systemData });
    } catch (error) {
      console.log(error);
    }
  };

  if (loggedIn) {
    // logout
    return (
      <>
        <div className={styles.logout}>
          <Link to="#" onClick={onLogout} className={[styles.logoutButton].join("")}>
            {t(props.labels, "logout_button_txt", "heb")}
          </Link>
          <Form.Label
            className={styles.labelName}
            style={{ padding: "4%", color: "white", width: "10rem" }}
          >
            {userName} שלום
          </Form.Label>
        </div>
        <div className={styles.logout_mob}>
          <Form.Label
            className={styles.labelName_mob}
            style={{ padding: "4%", color: "white", width: "10rem" }}
          >
            {userName} שלום
          </Form.Label>
          <br />
          <Link to="#" onClick={onLogout} className={[styles.logoutButton].join("")}>
            {t(props.labels, "logout_button_txt", "heb")}
          </Link>
        </div>
      </>
    );
  } else {
    //desktop login
    return (
      <>
        <div className={styles.login}>
          <Link
            to="/LoginPage"
            style={{
              color: pathname === "/LoginPage" ? "black" : "white",
              backgroundColor: pathname === "/LoginPage" ? "white" : "black",
            }}
            className={[styles.loginButton].join("")}
          >
            {t(props.labels, "login_button_txt", "heb")}
          </Link>
        </div>
        <div className={styles.login_mob}>
          <Link
            to="/LoginPage"
            style={{
              color: pathname === "/LoginPage" ? "black" : "white",
              backgroundColor: pathname === "/LoginPage" ? "white" : "black",
            }}
            className={[styles.loginButton_mob].join("")}
          >
            {t(props.labels, "login_button_txt", "heb")}
          </Link>
        </div>
      </>
    );
  }
}
